var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PlatformNoticeForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitBefore: _vm.submitBefore,
              footerShow: _vm.updatePermission,
            },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "标题",
                        prop: "title",
                        rules: [
                          {
                            required: true,
                            message: "请输入标题",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入标题", width: _vm.width },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.hasPermission
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "类型",
                            prop: "type",
                            rules: [
                              {
                                required: true,
                                message: "请选择类型",
                                trigger: "change",
                              },
                            ],
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              disabled: !_vm.isAdd,
                              options: _vm.noticeTypeOps,
                              width: _vm.width,
                            },
                            model: {
                              value: _vm.form.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "type", $$v)
                              },
                              expression: "form.type",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序",
                        prop: "sort",
                        rules: [
                          {
                            required: true,
                            message: "请输入排序",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input-number", {
                        attrs: {
                          max: 999,
                          min: 0,
                          placeholder: "请输入排序",
                          controls: "",
                          controlsPosition: "right",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "内容",
                        prop: "content",
                        rules: [
                          {
                            required: true,
                            message: "请输入内容",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-ueditor", {
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发布时间段",
                        prop: "noticeTimeStart",
                        rules: [
                          {
                            required: true,
                            message: "请选择时间段",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-datepicker", {
                        attrs: {
                          type: "rangedatetimer",
                          minDate: _vm.minDate,
                          startTime: _vm.form.noticeTimeStart,
                          endTime: _vm.form.noticeTimeEnd,
                        },
                        on: {
                          "update:startTime": function ($event) {
                            return _vm.$set(_vm.form, "noticeTimeStart", $event)
                          },
                          "update:start-time": function ($event) {
                            return _vm.$set(_vm.form, "noticeTimeStart", $event)
                          },
                          "update:endTime": function ($event) {
                            return _vm.$set(_vm.form, "noticeTimeEnd", $event)
                          },
                          "update:end-time": function ($event) {
                            return _vm.$set(_vm.form, "noticeTimeEnd", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isAdd
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }