<!--
 * @Description: 内容发布--平台公告表单页面
 * @Author: 小广
 * @Date: 2019-06-17 18:16:21
 * @LastEditors  : 小广
 * @LastEditTime : 2020-01-14 09:09:30
 -->
<template>
  <div class="PlatformNoticeForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
      :footerShow="updatePermission"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item
            label="标题"
            prop="title"
            :rules="[
              { required: true, message: '请输入标题', trigger: 'blur' }
            ]"
          >
            <v-input
              v-model="form.title"
              placeholder="请输入标题"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="类型"
            v-if="hasPermission"
            prop="type"
            :rules="[
              { required: true, message: '请选择类型', trigger: 'change' }
            ]"
          >
            <v-select
              v-model="form.type"
              :disabled="!isAdd"
              :options="noticeTypeOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="排序"
            prop="sort"
            :rules="[
              { required: true, message: '请输入排序', trigger: 'blur' }
            ]"
          >
            <v-input-number
              v-model="form.sort"
              :max="999"
              :min="0"
              placeholder="请输入排序"
              controls
              controlsPosition="right"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="内容"
            prop="content"
            :rules="[
              { required: true, message: '请输入内容', trigger: 'change' }
            ]"
          >
            <v-ueditor v-model="form.content"></v-ueditor>
          </el-form-item>
          <el-form-item
            label="发布时间段"
            prop="noticeTimeStart"
            :rules="[
              { required: true, message: '请选择时间段', trigger: 'blur' }
            ]"
          >
            <v-datepicker
              type="rangedatetimer"
              :minDate="minDate"
              :startTime.sync="form.noticeTimeStart"
              :endTime.sync="form.noticeTimeEnd"
            />
          </el-form-item>
          <el-form-item label="状态" v-if="!isAdd">
            <v-select
              v-model="form.status"
              :options="statusOps"
              :width="width"
            />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { createURL, editURL, queryURL, getNoticeTypeURL } from './api'
import { statusOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { vUeditor } from 'components/control'
import moment from 'moment'

export default {
  name: 'PlatformNoticeForm',
  components: {
    Col2Detail,
    Col2Block,
    vUeditor
  },
  data () {
    return {
      width: 182,
      statusOps: statusOps(),
      hasPermission: true, // 是否有权限选择全部类型
      noticeTypeOps: [],
      form: {
        id: undefined,
        title: '',
        type: undefined,
        sort: undefined,
        content: '',
        status: 1,
        noticeTimeStart: '',
        noticeTimeEnd: ''
      },
      submitConfig: {},
      minDate: moment().format('YYYY-MM-DD HH:mm'),
      isAdd: false
    }
  },

  computed: {
    updatePermission () {
      // 权限读取
      let view = false
      let permission = this.$store.state.menuStore.menuPermission['announcement1']
      if (permission.update === 1) {
        view = true
      }
      return view
    }
  },

  created () {
    const { id } = this.$route.query
    this.isAdd = !id
    this.submitConfig = {
      queryUrl: queryURL,
      submitUrl: this.isAdd ? createURL : editURL,
      submitMethod: this.isAdd ? 'post' : 'put',
      submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8'
    }
    // 平台、集团级用户，展示类型,其他用户，隐藏该字段。并且默认传值“公示公告”；
    let userInfo = this.$store.state.userInfo
    this.hasPermission = userInfo.userType === '106' || userInfo.userType === '100'
    // 获取公告类型
    this.getNoticeType()
    // 设置title
    let title = this.isAdd ? '新增公告' : '编辑公告'
    this.$setBreadcrumb(title)
  },

  mounted () {
    if (!this.isAdd) {
      const { id } = this.$route.query
      this.$refs.formPanel.getData({ id })
    }
  },

  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      if (!this.isAdd) {
        data.dataObject = data.title
      } else {
        delete data.id
      }

      if (data.noticeTimeStart.length === 0) {
        this.$message('发布时间不能为空')
        return false
      }

      if (data.noticeTimeEnd.length === 0) {
        this.$message('结束时间不能为空')
        return false
      }

      if (moment(data.noticeTimeEnd).isBefore(data.noticeTimeStart)) {
        this.$message('结束时间不能早于发布时间')
        return false
      }

      return true
    },

    // 获取公告类型
    getNoticeType () {
      let _this = this
      this.$axios.get(getNoticeTypeURL).then(res => {
        if (res.status === 100) {
          let noticeTypeList = []
          let list = res.data || []

          if (this.isAdd && !this.hasPermission) {
            // 公示公告类型ID，已和后台确认，各个环境id一致; 非平台、集团级用户，默认传值“公示公告”；
            let gsggId = '992866da-c8ea-11e5-bb37-008cfae40fdc'
            list = list.filter(item => item.id === gsggId)
          }

          list.forEach(item => {
            noticeTypeList.push({
              text: item.value,
              value: item.id
            })
          })
          if (this.isAdd && noticeTypeList.length) {
            // 添加是，默认取第一个类型
            _this.$set(_this.form, 'type', noticeTypeList[0].value)
          }
          _this.noticeTypeOps = noticeTypeList
        }
      })
    }
  }
}
</script>
